function init() {
	// console.log("Do ADA scripts.");

	// Fix multiple form labels issue with header search fields by making them unique
	const headerSearchBars = document.querySelectorAll(
		".site-header .search-form"
	);

	if (headerSearchBars.length) {
		headerSearchBars.forEach((headerSearchBar, i) => {
			const label = headerSearchBar.querySelector("label");
			const input = headerSearchBar.querySelector("input");

			input.setAttribute("id", "search-field" + i);
			label.setAttribute("for", input.id);
		});
	}

	// TST search widget fixes
	setTimeout(function () {
		// Fix broken ARIA menu
		const tstWidgetHotelFormRoomsSummary = document.querySelector(
			"#hotel-form .roomsSummary"
		);

		if (tstWidgetHotelFormRoomsSummary) {
			tstWidgetHotelFormRoomsSummary.setAttribute("role", "");
		}

		// Fix missing fieldset legend
		const tstWidgetHotelFieldset = document.querySelector(
			"fieldset.hotel-section"
		);
		const tstWidgetHotelFieldsetFirstChild = document.querySelector(
			"fieldset.hotel-section header.title-and-logo"
		);
		const tstWidgetHotelFieldsetLegendHTML =
			document.createElement("legend");

		if (tstWidgetHotelFieldset) {
			tstWidgetHotelFieldset.insertBefore(
				tstWidgetHotelFieldsetLegendHTML,
				tstWidgetHotelFieldsetFirstChild
			);

			const legend = document.querySelector(
				"fieldset.hotel-section legend"
			);
			const legendText = document.createTextNode("Choose hotel options");

			legend.appendChild(legendText);
			legend.classList.add("visually-hidden");
		}

		// Remove empty lists
		const tstWidgetFlightChildrenAgesDropdown = document.querySelector(
			"ul.childrenAgesDropdowns"
		);

		if (tstWidgetFlightChildrenAgesDropdown) {
			tstWidgetFlightChildrenAgesDropdown.remove();
		}
	}, 2000);

	// Fix missing label for mobile menu search field
	const mobileMenuSearchField = document.querySelector(
		".ast-mobile-popup-content .search-form > .search-field"
	);
	const mobileMenuSearchFieldLabel = document.querySelector(
		".ast-mobile-popup-content .search-form > .screen-reader-text"
	);

	if (mobileMenuSearchField) {
		mobileMenuSearchFieldLabel.setAttribute(
			"id",
			"mobile-search-field-label"
		);
		mobileMenuSearchField.setAttribute(
			"aria-labelledby",
			"mobile-search-field-label"
		);
	}

	// Fix empty lists
	setTimeout(function () {
		const emptyListUiAutocomplete =
			document.querySelector("ul.ui-autocomplete");

		if (emptyListUiAutocomplete) {
			emptyListUiAutocomplete.remove();
		}
	}, 2000);

	// Fix duplicate id issues with fixed header in Astra
	// document.querySelector("#ast-fixed-header #ast-desktop-header").id =
	// 	"ast-desktop-fixed-header";
	// document.querySelector(
	// 	"#ast-fixed-header #secondary_menu-site-navigation-desktop"
	// ).id = "secondary_menu-site-fixed-navigation-desktop";
	// document.querySelector("#ast-fixed-header #ast-hf-menu-2").id =
	// 	"ast-hf-fixed-menu-2";
	// document.querySelector(
	// 	"#ast-fixed-header #primary-site-navigation-desktop"
	// ).id = "primary-site-fixed-navigation-desktop";
	// document.querySelector("#ast-fixed-header #ast-hf-menu-1").id =
	// 	"ast-hf-fixed-menu-1";
	// document.querySelector("#ast-fixed-header #ast-mobile-header").id =
	// 	"ast-mobile-fixed-header";
}

export default { init };
